import { accounting } from '~public/js/src/salon/business/default/services/accounting.js'
import { canUseCamScanLogic } from '~src/appWebView.ts'
import { AndroidApp, appData } from '~src/globals/variables.ts'
import { $, jQuery } from '~src/globals/jquery.ts'
import { asyncEvent } from '~public/js/src/default/AsyncEvent.js'

export const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

// sends the sidpanel change event to nuxt
export async function dispatchSidePanel(interactionUser) {
  var mediaMini = $('body').hasClass('mini-navbar')
  var mediaMedium = $('body').hasClass('body-medium')
  var isVisible = (mediaMini && mediaMedium) || (!mediaMini && !mediaMedium)
  asyncEvent.dispatch('hc_sidepanel', {
    visible: isVisible,
    userInput: !!interactionUser,
  })
}

export function setModalBrowserUpdateItem(value) {
  var expiration = new Date()
  expiration.setMinutes(expiration.getMinutes() + 1440)

  // for items saved before the update to json objects
  if (typeof sessionStorage.getItem('modal-browser-update') === 'boolean') {
    sessionStorage.removeItem('modal-browser-update')
  }

  sessionStorage.setItem(
    'modal-browser-update',
    JSON.stringify({
      expiration: expiration,
      value: value,
    }),
  )
}

export function itemIsExpired(timestamp) {
  var now = new Date()
  var expires = new Date(timestamp)
  return now.getTime() > expires.getTime()
}

export function roundTimeToNearestFiveMinutes(timeValue) {
  var parts = timeValue.split(':')
  if (parts.length === 2) {
    var hours = parseInt(parts[0], 10)
    var minutes = parseInt(parts[1], 10)

    if (!isNaN(minutes)) {
      var remainder = minutes % 5
      var roundedMinutes
      if (remainder < 3) {
        roundedMinutes = minutes - remainder
      } else {
        roundedMinutes = minutes + (5 - remainder)
        if (roundedMinutes >= 60) {
          roundedMinutes = 0
          hours = (hours + 1) % 24
        }
      }

      return ('0' + hours).slice(-2) + ':' + ('0' + roundedMinutes).slice(-2)
    }
  }
  return timeValue
}

export function setLanguageFullCalendar() {
  // Dynamically load locales for fullCalendar. I found no solution to get rid of the relative path.
  // Will takle that down in future update
  return import(`../../../../../../resources/js/src/globals/i18n/fullcalendar/${appData.app.locale.toLowerCase()}.js`)
}

// type="email" will convert Umlaute to Punycode (https://www.punycoder.com/)
// xn-- is the prefix for Punycode
export function emailIncludesUmlaut(email) {
  return email.includes('@xn--')
}

export function setAccountingAjaxExecuted(value) {
  accounting.ajaxExecuted = value
}

export function minutesToHHMM(value) {
  value = Math.round(value)
  var hour = Math.floor(value / 60)
  var min = value - hour * 60
  return ('0' + hour).slice(-2) + ':' + ('0' + min).slice(-2)
}

export function HHMMtoMinutes(value) {
  var split = value.toString().split(':')
  var hour = parseInt(split[0], 10) * 60
  var min = parseInt(split[1], 10)
  return hour + min
}

export function setTimeline(view) {
  var parentDiv = jQuery('.fc-agenda-slots:visible').parent()
  var timeline = parentDiv.children('.timeline')
  if (timeline.length === 0) {
    //if timeline isn't there, add it
    timeline = jQuery('<hr>').addClass('timeline')
    parentDiv.prepend(timeline)
  }

  var curTime = new Date()
  if (view.start < curTime && view.end > curTime) {
    timeline.show()
  } else {
    timeline.hide()
    return
  }

  var calMinTimeInMinutes = HHMMtoMinutes(view.opt('minTime'))
  var calMaxTimeInMinutes = HHMMtoMinutes(view.opt('maxTime'))
  var curSeconds = (curTime.getHours() * 60 + curTime.getMinutes() - calMinTimeInMinutes) * 60 + curTime.getSeconds()
  var percentOfDay = curSeconds / ((calMaxTimeInMinutes - calMinTimeInMinutes) * 60)
  var topLoc = Math.floor(parentDiv.height() * percentOfDay)

  timeline.css('top', topLoc + 'px')

  if (view.name == 'agendaWeek') {
    var dayCol = jQuery('.fc-today:visible')
    var left = dayCol.position().left + 1
    var width = dayCol.width() - 2
    timeline.css({
      left: left + 'px',
      width: width + 'px',
    })
  }
}

export function SmoothlyMenu() {
  if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small') || $('body').hasClass('body-medium')) {
    // Hide menu in order to smoothly turn on when maximize menu
    $('#side-menu').hide()
    $('.navbar-logo-bottom').hide()
    // For smoothly turn on menu
    setTimeout(function () {
      $('#side-menu').fadeIn(500)
      $('.navbar-logo-bottom').fadeIn(500)
    }, 100)
  } else if ($('body').hasClass('fixed-sidebar')) {
    $('#side-menu').hide()
    $('.navbar-logo-bottom').hide()
    setTimeout(function () {
      $('#side-menu').fadeIn(500)
      $('.navbar-logo-bottom').fadeIn(500)
    }, 300)
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    $('#side-menu').removeAttr('style')
    $('.navbar-logo-bottom').removeAttr('style')
  }
}

export function decimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) {
    return 0
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0),
  )
}

export function trimTrailingChars(s, charToTrim) {
  var regExp = new RegExp(charToTrim + '+$')
  var result = s.replace(regExp, '')

  return result
}

export const delay = (function () {
  var timer = 0
  return function (callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

export function callScanner(scanType, defaultScanModal) {
  if (canUseCamScanLogic()) {
    AndroidApp().callBarcodeScanner(scanType)
  } else {
    defaultScanModal.modal('show')
  }
}

export function Sanitize() {}

Sanitize.escapeInt = function (str) {
  const output = String(str).match(/[0-9]+/)
  return output === null ? '' : output[0]
}
