// Bundle size issue. Es werden alle Sprachen geladen (u.a. chinesisch), obwohl nur paar benötigt werden.
// See https://github.com/Adyen/adyen-web/issues/337
// @ts-ignore
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types/index.d.ts'
// @ts-ignore
import type Core from '@adyen/adyen-web/dist/types/core/core.d.ts'
import { mountModalPayment } from '~public/js/src/salon/business/default/pages/intern/company/accounting'
import { resetButton, toggleModal } from '~src/jquery.ts'
import { request, ResponseError } from '~src/request.ts'
import { toastError } from '~src/globals/toastr.ts'
import { sleep } from '~src/utils.ts'
import { $ } from '~src/globals/jquery.ts'
import { setAccountingAjaxExecuted } from '~public/js/src/salon/business/default/custom-utils.js'
import { loadModule } from '~public/js/src/salon/dynamicModule.ts'
import { adyenData, appData } from '~src/globals/variables.ts'

async function getCheckoutInstance(): Promise<Core> {
  const { default: AdyenCheckout } = await import('@adyen/adyen-web')

  return AdyenCheckout(getAdyenConfiguration())
}

export let adyenEncryptedCard: null | Record<string, string> = null

function registerEventListeners() {
  window.addEventListener('TsAdyen', ((
    event: CustomEvent<{
      action: 'createFromAction' | 'fillCreditCard'
      payload: PaymentAction
      encryptedCard: Record<string, string>
    }>,
  ) => {
    if (event.detail.action === 'fillCreditCard') {
      adyenEncryptedCard = event.detail.encryptedCard
      return
    }

    if (event.detail.action !== 'createFromAction') {
      return
    }

    setTimeout(async function () {
      resetButton('#form-checkout button[type=submit]')
      // @ts-ignore global accounting not working
      setAccountingAjaxExecuted(false)
    }, 2000)

    void getCheckoutInstance().then((client) => {
      client.createFromAction(event.detail.payload).mount('#threeDS2-container')
    })
  }) as EventListener)
}

function toggleThreeDs2Modal(show: boolean) {
  toggleModal('#modal-threeDS2', show)
}

async function onAdditionalDetails(result: { accountingType: number; data: { details: { threeDSResult: string } } }) {
  toggleThreeDs2Modal(false)
  const accounting = await loadModule.accounting

  request<{ error: string }>('/api/intern/adyen/submit-authentication-result', {
    method: 'POST',
    body: {
      accountingType: accounting.mode,
      threeDSResult: result.data.details.threeDSResult,
    },
  })
    .then((json) => {
      if (json.error) {
        toastError(json.error)
        return
      }

      toggleModal('#modal-checkout', false)
      accounting.handleSiteSpecificAction()
      accounting.showPaymentStatusModal(json)
    })
    .catch(async (error: Error | ResponseError<string>) => {
      if (error instanceof ResponseError) {
        if (error.response.status === 422) {
          return
        }
        toastError(error.body.message)
        return
      }

      toastError(error.message)
    })
}

async function onActionHandled(data: { componentType: string; actionDescription: string }) {
  if (data.componentType === '3DS2Challenge') {
    toggleThreeDs2Modal(true)
  }
}

export async function mount() {
  if (!document.querySelector('#adyen-container')) {
    return
  }

  mountModalPayment()

  registerEventListeners()

  //adyen iframe not loading without sleep
  await sleep(100)
  const client: Core = await getCheckoutInstance()

  client
    .create('securedfields', {
      type: 'card',
      brands: ['mc', 'visa', 'cartebancaire', 'maestro'],
      autoFocus: false,
      styles: {
        base: {
          color: '#676a6c',
          fontSize: '14px !important',
          fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
          padding: '0 12px',
        },
        error: {
          color: '#ed5565',
        },
        validated: {
          color: '#1ab394',
        },
        placeholder: {
          color: '#999 !important',
          opacity: 1,
          fontWeight: 500,
          fontSize: '14px !important',
        },
      },

      onChange: function (state: { isValid: boolean; data: Record<string, Record<string, unknown>> }) {
        let creditCardData = null

        if (state.isValid) {
          const data = state.data.paymentMethod
          creditCardData = {
            encryptedCardNumber: data.encryptedCardNumber,
            encryptedExpiryMonth: data.encryptedExpiryMonth,
            encryptedExpiryYear: data.encryptedExpiryYear,
            encryptedSecurityCode: data.encryptedSecurityCode,
            browserInformation: state.data.browserInfo,
          }
        }

        window.dispatchEvent(
          new CustomEvent('TsAdyen', {
            detail: {
              action: 'fillCreditCard',
              encryptedCard: creditCardData,
            },
          }),
        )
      },
      onLoad: function () {
        $('.adyen-input.loading').removeClass('loading')
      },
      onFocus: function (event: { fieldType: string; focus: boolean }) {
        const fieldType = event.fieldType
        $('.adyen-input.' + fieldType).toggleClass('focus', event.focus)
      },
    })
    .mount('#adyen-container')
}

function getAdyenConfiguration() {
  if (!adyenData?.clientKey) {
    throw new Error('adyenData is not available')
  }
  const companyData = appData.custom.company
  if (!companyData) {
    throw new Error('companyData is not available')
  }

  return {
    locale: companyData.locale,
    environment: appData.app.environment === 'production' ? 'live' : 'test',
    clientKey: adyenData?.clientKey,
    onAdditionalDetails: onAdditionalDetails,
    onActionHandled: onActionHandled,
  }
}
