import { accounting } from '~public/js/src/salon/business/default/services/accounting.js'
import { isIOS } from '~src/appWebView.ts'
import { $ } from '~src/globals/jquery.ts'
import { toastr } from '~src/globals/toastr.ts'
import { getLocales } from '~src/globals/i18n/internLocales.ts'
const lang = getLocales()

export function mount() {
  //Get payment details
  accounting.getPaymentDetails()

  //Get invoices
  accounting.getInvoices(accounting.index, false)

  //Change payment button clicked (set type and show modal)
  $('.btn-change-payment').on('click', function (e) {
    e.preventDefault()

    if (isIOS()) {
      $('#modal-ios-payment-prevent').modal('show')
    } else {
      accounting.mode = -1

      $('#modal-checkout .checkout-product').html($(this).data('title'))
      $('#modal-checkout .checkout-text').html($(this).data('text'))
      $('#modal-checkout .check-voucher-code-box').hide()
      $('#modal-checkout').modal('show')
    }
  })

  //Submit invoice address form
  $('#form-change-invoice-address').submit(function (event) {
    event.preventDefault()
    //Submit data
    accounting.saveInvoiceAddress($(this), $(this).serialize(), true)
  })

  //Invoice clicked (open additional table row)
  $('.invoices-box').on('click', 'tr.clickable td', function () {
    1
    //Set row
    var row = $(this).parent('tr').next()
    //Show hide row
    if (row.is(':visible')) {
      $(this).parent('tr').toggleClass('active')
      row.hide()
    } else {
      $(this).parent('tr').toggleClass('active')
      row.show()
    }
  })

  //Pagination
  $('.invoices-box').on('click', '.pagination a', function () {
    var index = $(this).data('index')
    if (index !== undefined) {
      //Get invoices
      accounting.getInvoices(index, true)
    }
  })
}

export function mountModalPayment() {
  $(
    '#modal-checkout, #modal-buy-certificate, #modal-yearly-invoice-check-packages, #modal-tse-purchase, #modal-voucher-redeem',
  ).on('click', '.check-voucher-code button', function () {
    var value = $(this).parents('.check-voucher-code').find('input').val()
    var modal = $(this).parents('.modal')

    if (value !== '') {
      if ($(this).closest('.modal').attr('id') === 'modal-yearly-invoice-check-packages') {
        accounting.mode = 5
      }

      accounting.checkVoucherCode($(this), value, modal)
    }
  })

  $('#form-voucher-redeem').on('submit', function (e) {
    e.preventDefault()

    if ($(this).find('input[name=voucher-code]').val()) {
      accounting.saveVoucherRedeem($(this).serializeJSON(), $(this).find('button[type=submit]'))
    } else {
      toastr.error(lang.check_voucher_code)
    }
  })

  $('#form-voucher-redeem').on('keydown', function (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  })

  //Payment button clicked (change payment method)
  $('#form-checkout .btn-payment').on('click', function () {
    //Ignore if current payment method is clicked
    if (!$(this).hasClass('active')) {
      //Remove active class form all payment buttons
      $('#form-checkout .btn-payment').removeClass('active')

      //Set clicked button as active
      $(this).addClass('active')

      //Hide all payment method containers
      $('#form-checkout .payment-container').hide()

      //Toggle corresponding payment box
      $('#form-checkout .payment-container[data-payment="' + $(this).data('payment') + '"]').show()

      //Remove required fields
      $('#form-checkout .payment-container input').prop('required', false)

      //Set all fields as required
      $('#form-checkout .payment-container[data-payment="' + $(this).data('payment') + '"] input').prop(
        'required',
        true,
      )

      if ($(this).data('payment') !== 'credit-card') {
        $('#address-optional-hint').show()
        $('.address-required-hint').hide()
        $('#form-checkout .address-container input').prop('required', false)
      } else {
        $('#address-optional-hint').hide()
        $('.address-required-hint').show()
        $('#form-checkout .address-container input:not(#uid_number)').prop('required', true)
      }
    }
  })

  $('#form-checkout').on('submit', function (event) {
    event.preventDefault()

    const paymentMethod = $('#form-checkout .btn-payment.active').data('payment')

    if (paymentMethod === 'sepa') {
      const cvcInputValue = $(this).find('#sepa-iban').val()
      const ibanPattern = /[a-zA-Z]{2}[a-zA-Z0-9\s]{10,}/

      if (!ibanPattern.test(cvcInputValue)) {
        toastr.error(lang.wrong_iban)
        return false
      }
    }

    accounting.handlePayment($(this))
  })

  //Add space after 4 characters (iban)
  $('#modal-checkout #sepa-iban').keyup(function () {
    let foo = $(this).val().split(' ').join('')
    if (foo.length > 0) {
      foo = foo.match(new RegExp('.{1,4}', 'g')).join(' ')
    }
    $(this).val(foo)
  })
}

export function mountPaymentApproval() {
  $('.payment-approval')
    .find('a')
    .on('click', function (e) {
      e.stopPropagation()
      e.preventDefault()
    })

  //Payment approval
  $('#modal-payment-approval #form-payment-approval').on('submit', function (e) {
    e.preventDefault()
    accounting.paymentApproval($(this))
  })
}
