import { products } from '~public/js/src/salon/business/default/services/products.js'
import { signatureDe } from '~public/js/src/salon/business/default/services/signatureDe.js'
import { adyenEncryptedCard } from '~src/adyen.ts'
import { getLocales } from '~src/globals/i18n/internLocales.ts'
const lang = getLocales()
import { toastr } from '~src/globals/toastr.ts'
import { $, jQuery } from '~src/globals/jquery.ts'

export const accounting = {
  mode: undefined,
  index: 1,
  ajaxExecuted: false,
  modalVoucherRedeem: $('#modal-voucher-redeem'),

  handleRequestError: function (error) {
    if (error && error.status !== 422 && error.status !== 409) {
      toastr.error(lang.generic_error)
    }
    $('#form-checkout').find('button[type=submit]').button('reset')
    accounting.ajaxExecuted = false
  },

  getPaymentDetails: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/accounting/payment-details',
      beforeSend: function () {
        $('.payment-method-field').next('.spinner').show()
      },
      complete: function () {
        $('.payment-method-field').next('.spinner').hide()
      },
      success: function (json) {
        $('.payment-method-field').html(json.html)
      },
    })
  },

  getVoucherDetails: function () {
    $('.vouchers-box').addClass('load')
    $('.vouchers-box .spinner').show()
    $.request({
      method: 'POST',
      url: '/company/accounting/voucher-details',
    })
      .then(function (res) {
        $('.vouchers-box .table tbody').html(res.html)
      })
      .catch(function () {})
      .finally(function () {
        $('.vouchers-box').removeClass('load')
        $('.vouchers-box .spinner').hide()
      })
  },

  saveInvoiceAddress: function (form, data, setData) {
    void $.ajax({
      type: 'POST',
      data: data,
      url: '/api/intern/company/accounting/save-invoice-address',
      beforeSend: function () {
        form.find('button[type=submit]').button('loading')
      },
      complete: function () {
        form.find('button[type=submit]').button('reset')
      },
      success: function (json) {
        if (!json.error) {
          //Set data
          if (setData) {
            $('.invoice-address-name').text(json.data.name)
            $('.invoice-address-address').html(json.data.address)
            $('.invoice-address-uid-number').text(json.data.uidNumber)
          }

          //Hide modal
          $('#modal-change-invoice-address').modal('hide')

          //Show success message
          toastr.success(json.msg)
        } else {
          toastr.error(json.error)
        }
      },
    })
  },

  getInvoices: function (index, scroll) {
    void $.ajax({
      type: 'POST',
      data: {
        index: index,
      },
      url: '/api/intern/company/accounting/invoices/get',
      beforeSend: function () {
        $('.invoices-box').addClass('load')
        $('.invoices-box .spinner').show()
      },
      complete: function () {
        $('.invoices-box').removeClass('load')
        $('.invoices-box .spinner').hide()
      },
      success: function (json) {
        $('.invoices-box .table tbody').html(json.html)
        $('.invoices-box .pagination-box').html(json.pagination)
        if (scroll) {
          jQuery('html, body').animate(
            {
              scrollTop: $('.invoices-box').parents('.tab-pane').offset().top,
            },
            600,
          )
        }
      },
    })
  },

  paymentApproval: function (form) {
    var paymentApproval = $('#modal-payment-approval .payment-approval input[name=payment-approval]').is(':checked')
      ? true
      : false

    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/accounting/payment-approval',
      data: {
        'payment-approval': paymentApproval,
      },
      beforeSend: function () {
        form.find('button[type=submit]').button('loading')
      },
      complete: function () {
        form.find('button[type=submit]').button('reset')
      },
      success: function (json) {
        if (!json.error) {
          $('#modal-payment-approval').modal('hide')
          toastr.success(json.msg)
        } else {
          toastr.error(json.error)
        }
      },
    })
  },

  handlePayment: function (form) {
    if (!accounting.ajaxExecuted) {
      accounting.ajaxExecuted = true

      var paymentMethod = $('#form-checkout .btn-payment.active').data('payment')
      var ignoreFields = [
        'credit-card-number',
        'credit-card-expire-month',
        'credit-card-expire-year',
        'credit-card-cvc',
      ]

      var data = form.serializeArray().filter((val) => ignoreFields.indexOf(val.name) === -1)

      data.push({ name: 'payment-method', value: paymentMethod })
      data.push({ name: 'result-url', value: window.location.href.split('?')[0] })
      data.push({ name: 'accounting-mode', value: accounting.mode })

      if (paymentMethod === 'credit-card') {
        if (adyenEncryptedCard === null) {
          toastr.error(lang.wrong_credit_card_data)
          accounting.ajaxExecuted = false
          return
        }

        data.push({
          name: 'encrypted-data',
          value: JSON.stringify(adyenEncryptedCard),
        })
      }

      void $.ajax({
        type: 'POST',
        url: '/api/intern/company/accounting/payment',
        data: data,
        beforeSend: function () {
          form.find('button[type=submit]').button('loading')
        },
        success: function (json) {
          let submitButton = form.find('button[type=submit]')

          if (json.error) {
            toastr.error(json.error)

            if (typeof json.errorType !== 'undefined' && json.errorType === 'payment-approval') {
              $('input[name="payment-approval"]').parents('.payment-approval').addClass('has-error')
            }

            submitButton.button('reset')
            accounting.ajaxExecuted = false
            return
          }

          if (paymentMethod === 'directEbanking' || paymentMethod === 'paypal' || paymentMethod === 'trustly') {
            location.href = json.url
            return
          }

          if (paymentMethod === 'credit-card') {
            if (json.action) {
              window.dispatchEvent(
                new CustomEvent('TsAdyen', { detail: { action: 'createFromAction', payload: json.action } }),
              )
              return
            }
          }

          accounting.handleSiteSpecificAction()
          accounting.showPaymentStatusModal(json)
          submitButton.button('reset')
          accounting.ajaxExecuted = false
        },
        error: accounting.handleRequestError,
      })
    }
  },

  handleSiteSpecificAction: function () {
    if ($('body[id="/intern/cash-register/signature/signature-de"]').length > 0) {
      signatureDe.reloadTseSteps()
    } else {
      if (accounting.mode >= 0 && accounting.mode <= 3) {
        setTimeout(function () {
          products.loadProductCashRegister()
        }, 2000)
      }

      $('#modal-cash-register-products').modal('hide')
      $('#modal-support-modell').modal('hide')
    }

    if (accounting.mode === 4) {
      $('#modal-buy-certificate').modal('hide')
    } else if (accounting.mode === 11) {
      $('#modal-tse-purchase').modal('hide')
    } else if (accounting.mode === -1) {
      accounting.getPaymentDetails()
      accounting.getInvoices(1, false)
    }
  },

  checkVoucherCode: function (btn, code, modal) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/accounting/check-voucher-code',
      data: {
        code: code,
        'accounting-type': accounting.mode,
      },
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (json) {
        modal.find('.voucher-redeem-success').hide()
        modal.find('.voucher-redeem-failure').hide()
        //Reset voucher saving text
        modal.find('.checkout-voucher').text('')

        //Check result
        if (json.error) {
          if (accounting.modalVoucherRedeem.is(':visible')) {
            accounting.modalVoucherRedeem.find('[data-valid-for]').addClass('tw-hidden')
          }
          modal.find('.voucher-redeem-failure').show()
          return
        }

        if (accounting.modalVoucherRedeem.is(':visible')) {
          accounting.modalVoucherRedeem.find('[data-valid-for]').removeClass('tw-hidden')
          accounting.modalVoucherRedeem.find('[data-valid-for]').find('span').text(json.validFor)
        }

        modal.find('.checkout-voucher').text('(' + json.msgSaving + ')')
        modal.find('.voucher-redeem-success').show()
      },
    })
  },

  saveVoucherRedeem: function (data, button) {
    $.request({
      method: 'POST',
      url: '/company/accounting/save-voucher-redeem',
      json: data,
      loadingButton: button,
    })
      .then(function (data) {
        accounting.getVoucherDetails()
        $('#modal-voucher-redeem').modal('hide')

        $('#form-voucher-redeem')[0].reset()
        accounting.modalVoucherRedeem.find('.voucher-redeem-success').hide()
        accounting.modalVoucherRedeem.find('.voucher-redeem-failure').hide()
        accounting.modalVoucherRedeem.find('.checkout-voucher').text('')
        accounting.modalVoucherRedeem.find('[data-valid-for]').addClass('tw-hidden')

        toastr.success(data.msg)
      })
      .catch(function (error) {
        if (error.responseJSON.msg) {
          toastr.error(error.responseJSON.msg)
        }
      })
  },

  showPaymentStatusModal: function (json) {
    const modal = $('#modal-payment-status')
    modal.find('.modal-title').text(json.title !== '' ? json.title : '')
    modal.find('.text').html(json.text !== '' ? json.text : '')

    if (typeof json.success !== 'undefined') {
      const iconToShow = json.success ? '.fa-check' : '.fa-xmark'
      const iconToHide = json.success ? '.fa-xmark' : '.fa-check'

      modal.find(iconToShow).show()
      modal.find(iconToHide).hide()
    }

    $('#modal-checkout').modal('hide')

    setTimeout(function () {
      $('#modal-payment-status').modal('show')
    }, 1000)
  },
}
