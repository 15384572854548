import { accounting } from '~public/js/src/salon/business/default/services/accounting.js'
import { helper } from '~public/js/src/salon/business/default/services/helper.js'
import { getLocales } from '~src/globals/i18n/internLocales.ts'
const lang = getLocales()
import { toastr } from '~src/globals/toastr.ts'
import { $ } from '~src/globals/jquery.ts'

export const signatureDe = {
  modalEditCompanyData: $('#modal-edit-company-data'),
  modalTsePurchase: $('#modal-tse-purchase'),
  formEditCompanyData: $('#form-edit-company-data'),
  btnPurchaseTse: $('.btn-purchase-tse'),
  stepWrapper: $('.step-wrapper'),
  formExportTse: $('#form-export-tse'),
  formDecommission: $('#form-decommission-tse'),
  tseExportBox: $('.tse-export-box'),
  tseExportDataLoaded: false,
  ajaxExecuted: false,

  handleRequestError: function () {
    signatureDe.ajaxExecuted = false
    toastr.error(lang.generic_error)
  },

  reloadTseSteps: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/cash-register/signature/de/get-tse-steps',
      beforeSend: function () {
        signatureDe.stepWrapper.addClass('load')
      },
      complete: function () {
        signatureDe.stepWrapper.removeClass('load')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        signatureDe.stepWrapper.html(json.html)
      },
    })
  },

  editCompanyData: function (form) {
    void $.ajax({
      type: 'POST',
      data: form.serialize(),
      url: '/api/intern/company/edit',
      beforeSend: function () {
        form.find('button[type=submit]').button('loading')
      },
      complete: function () {
        form.find('button[type=submit]').button('reset')
      },
      success: function (json) {
        if (!json.error) {
          signatureDe.modalEditCompanyData.modal('hide')
          toastr.success(json.msg)
          signatureDe.reloadTseSteps()
        } else {
          helper.showError(json.error)
        }
      },
    })
  },

  loadTsePurchaseModal: function (btn) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/cash-register/signature/de/get-tse-purchase-data',
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)
        var company = json.company

        var address =
          company.street +
          ' ' +
          company.house_number +
          '<br>' +
          company.postal_code +
          ' ' +
          company.city +
          '<br>' +
          company.country

        signatureDe.modalTsePurchase.find('.company-name').text(company.name)
        signatureDe.modalTsePurchase.find('.company-address').html(address)
        signatureDe.modalTsePurchase
          .find('.company-uid-tax-number')
          .html(company.uid_number + '<br>' + company.tax_number)

        signatureDe.modalTsePurchase.find('.voucher-redeem-box').css('display', json.voucher_redeem ? 'block' : 'none')

        signatureDe.modalTsePurchase.modal('show')
      },
    })
  },

  purchaseTse: function (btn, voucherCode) {
    if (signatureDe.modalTsePurchase.find('input[name=conditions]').not(':checked').length > 0) {
      toastr.error(signatureDe.modalTsePurchase.find('input[name=conditions]').data('error'))
      return false
    }

    if (signatureDe.ajaxExecuted) {
      return false
    }

    signatureDe.ajaxExecuted = true

    void $.ajax({
      type: 'POST',
      url: '/api/intern/cash-register/signature/de/purchase-tse',
      data: {
        'voucher-code': voucherCode,
      },
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (!json.error) {
          if (json.showPaymentModal) {
            accounting.mode = 11

            $('#modal-checkout .checkout-product').text(btn.data('name'))
            $('#modal-checkout .checkout-price').text(btn.data('price'))

            $('#modal-checkout').modal('show')
          } else {
            $('#modal-payment-status .modal-title').text(json.title)
            $('#modal-payment-status .text').html(json.text)

            $('#modal-payment-status .fa-check').show()
            $('#modal-payment-status .modal-body .fa-xmark').hide()

            signatureDe.modalTsePurchase.modal('hide')
            $('#modal-payment-status').modal('show')

            signatureDe.reloadTseSteps()
          }
        } else {
          toastr.error(json.error)
        }

        signatureDe.ajaxExecuted = false
      },
      error: signatureDe.handleRequestError,
    })
  },

  triggerTseExport: function (data, button) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/cash-register/signature/de/trigger-export',
      data: data,
      beforeSend: function () {
        button.button('loading')
      },
      complete: function () {
        button.button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (!json.error) {
          toastr.success(json.msg)
        } else {
          toastr.error(json.error)
        }

        signatureDe.getTseExports(signatureDe.formExportTse.serialize(), 1)
      },
    })
  },

  getTseExports: function (data, index) {
    void $.ajax({
      type: 'POST',
      data: data + '&index=' + index,
      url: '/api/intern/cash-register/signature/de/get-tse-exports',
      beforeSend: function () {
        signatureDe.tseExportBox.addClass('load')
        signatureDe.tseExportBox.find('.spinner').show()
      },
      complete: function () {
        signatureDe.tseExportBox.removeClass('load')
        signatureDe.tseExportBox.find('.spinner').hide()
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        signatureDe.tseExportBox.find('.table tbody').html(json.html)
        signatureDe.tseExportBox.find('.pagination-box').html(json.pagination)

        signatureDe.tseExportDataLoaded = true
      },
    })
  },

  decommissionTse: function (btn, data) {
    void $.ajax({
      type: 'POST',
      data: data,
      url: '/api/intern/cash-register/signature/de/decommission-tse',
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (data) {
        var json = $.parseJsonString(data)

        if (!json.error) {
          toastr.success(json.msg)

          $('.tse-decommission-box').hide()
          $('#modal-decommission-tse').modal('hide')

          signatureDe.reloadTseSteps()
        } else {
          toastr.error(json.error)
        }
      },
    })
  },
}
