import { decimalPlaces, trimTrailingChars } from '~public/js/src/salon/business/default/custom-utils.js'
import { getLocales } from '~src/globals/i18n/internLocales.ts'
const lang = getLocales()
import { toastr } from '~src/globals/toastr.ts'
import { round } from '~public/js/src/utils.js'
import { $ } from '~src/globals/jquery.ts'

export const helper = {
  maxUploadFileSize: 5120000,
  image: null,

  getUrlParameters: function (parameters) {
    const urlParams = new URLSearchParams(window.location.search)
    let result = {}

    for (var i = 0; i < parameters.length; i++) {
      let parameter = parameters[i]
      result[parameter] = urlParams.get(parameter)
    }

    return result
  },

  clearErrorFields: function (form) {
    form.find('.form-group').removeClass('has-error')
  },

  clearInputField: function (form) {
    form.find('input').val('')
    form.find('textarea').val('')
  },

  resetSelects: function (form) {
    form.find('select:not([data-default])').prop('selectedIndex', 0)
    form.find('select[data-default]').each(function () {
      $(this)
        .find('option[value="' + $(this).data('default') + '"]')
        .prop('selected', 'selected')
    })
  },

  checkInput: function (data, obj, number) {
    var $error = false

    //Check data
    if (number) {
      if (data !== '' && !$.isNumeric(data)) {
        $error = true
      }
    } else if (data === '') {
      $error = true
    }

    if ($error) {
      obj.parents('.form-group').addClass('has-error')
      return false
    } else {
      obj.parents('.form-group').removeClass('has-error')
      return true
    }
  },

  calculateGrossPrice: function (price, tax) {
    return helper.moneyFormat((price / 100) * (100 + tax), '', 2, 3)
  },

  calculateNetPrice: function (price, tax) {
    return helper.moneyFormat((price / (100 + tax)) * 100, '', 2, 3)
  },

  resizeBase64Image: function (img, width, height) {
    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d')

    // set its dimension to target size
    canvas.width = width
    canvas.height = height

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height)

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL()
  },

  moneyFormat: function (value, currency, dec_min, dec_max) {
    let $num = value.toString().replace(',', '.')
    $num = round(parseFloat($num), dec_max)
    if ($num.toString().indexOf('.') > 0) {
      $num = trimTrailingChars($num.toString(), '0')
    }
    if (decimalPlaces($num) < dec_min) {
      $num = parseFloat($num).toFixed(dec_min)
    }

    return $num.toString().replace('.', ',') + currency
  },

  getNumberFromPrice: function (value) {
    let $num = value.toString()
    $num = $num.replace(',', '.')
    return parseFloat($num)
  },

  printingProcess: function (modal, duration) {
    if (modal.find('.printing').length) {
      modal.find('.finished-printing').hide()
      modal.find('.printing').show()
      setTimeout(function () {
        modal.find('.printing').hide()
        modal.find('.finished-printing').show()
      }, duration)
    }
  },

  sortableTableHelper: function (e, ui) {
    ui.children().each(function () {
      $(this).width($(this).width())
    })
    return ui
  },

  checkImportFile: function (file, type, modal) {
    if (typeof file !== 'undefined') {
      const error = []
      //Check min file size
      if (file.size < 1) {
        error.push(lang.import_empty)
      }
      //Check max file size
      if (file.size > helper.maxUploadFileSize) {
        error.push(lang.import_size_5)
      }
      //Check mime type
      if (file.name.match(/.+(\.csv)$/) === null) {
        error.push(lang.import_type)
      }

      //Set submit button and filename
      if (error.length < 1) {
        type.importFile = file
        modal.find('.filename').text(file.name)
        modal.find('button[type=submit]').prop('disabled', false)
      } else {
        type.importFile = null
        modal.find('.filename').text('')
        modal.find('button[type=submit]').prop('disabled', true)

        //Show errors
        helper.showError(error)
      }
    }
  },

  showError: function (error) {
    if (typeof error === 'string') {
      toastr.error(e)
      return
    }

    var e = ''
    error.forEach(function (entry) {
      e = e + entry + '<br />'
    })
    toastr.error(e)
  },

  //Set date range within two datepickers
  dateRange: function (from, to, months, endDate = undefined, setEndDate = true) {
    // caution: datepicker options `endDate: '+0d'` must be set!

    var clampDate
    if (!endDate) {
      clampDate = new Date()
    } else {
      clampDate = endDate
    }

    var fromDate = from.datepicker('getDate')

    var toDate = to.datepicker('getDate')
    if (toDate === null) {
      toDate = fromDate
    }
    $(to).datepicker('setStartDate', fromDate)

    var fromWithMonths = new Date(fromDate)
    fromWithMonths.setMonth(fromDate.getMonth() + months)

    // clamp from-with-months to max today
    if (fromWithMonths.getTime() > clampDate.getTime()) {
      fromWithMonths = clampDate
      if (setEndDate) {
        $(to).datepicker('setEndDate', clampDate)
      }
    } else {
      if (setEndDate) {
        $(to).datepicker('setEndDate', fromWithMonths)
      }
    }

    // clamp to-date to from-with-months
    if (toDate.getTime() > fromWithMonths.getTime()) {
      $(to).datepicker('setDate', fromWithMonths)
    } else {
      $(to).datepicker('setDate', toDate)
    }
  },

  //Set from date of date range within two datepickers
  clampFromDateRange: function (from, to, months) {
    var fromDate = from.datepicker('getDate')
    var toDate = to.datepicker('getDate')

    var toWithMonths = new Date(toDate)
    toWithMonths.setMonth(toDate.getMonth() - months)

    if (fromDate.getTime() < toWithMonths.getTime()) {
      $(from).datepicker('setDate', toWithMonths)
    }
  },

  setPreviewImage: function (input, container, showContainer) {
    showContainer = typeof showContainer === 'undefined' ? 'true' : showContainer
    const imageObject = new Image()
    const reader = new FileReader()

    reader.onloadend = function () {
      helper.image = reader.result
      imageObject.src = helper.image

      imageObject.onload = function () {
        const width = parseInt(imageObject.width)
        const height = parseInt(imageObject.height)

        if (showContainer) {
          if (width > height && width > 330) {
            helper.image = helper.resizeBase64Image(imageObject, 330, parseInt((330 / width) * height))
          } else if (height > 300) {
            helper.image = helper.resizeBase64Image(imageObject, parseInt((300 / height) * width), 300)
          }
        }

        container.find('.preview-image').attr('src', helper.image)

        if (showContainer) {
          container.find('.preview-image').show()
          container.show()
          container.find('input[class=show-image]').prop('checked', true)
        }
      }
    }

    reader.readAsDataURL(input[0].files[0])
  },

  createSelectOptionIfValueNotExists: function (selectElement, value, text) {
    var options = selectElement.find('option[value="' + value + '"]')

    if (options.length !== 0) {
      return
    }

    selectElement.append(new Option(text, value))
  },
}
