import { accounting } from '~public/js/src/salon/business/default/services/accounting.js'
import { helper } from '~public/js/src/salon/business/default/services/helper.js'
import { signatureDe } from '~public/js/src/salon/business/default/services/signatureDe.js'
import { companyData } from '~src/globals/variables.ts'
import { toastr } from '~src/globals/toastr.ts'
import { $ } from '~src/globals/jquery.ts'

export const products = {
  setModeProcess: false,
  newMode: null,
  modalVoucherFreePeriod: $('#modal-voucher-free-period'),
  modalSupportModell: $('#modal-support-modell'),
  name: null,
  price: null,

  setCashRegisterMode: function (btn, mode, supportModel = null) {
    if (!products.setModeProcess) {
      products.setModeProcess = true

      void $.ajax({
        type: 'POST',
        url: '/api/intern/company/products/set-cashregister-mode',
        data: {
          mode: mode,
          support_model: supportModel,
        },
        beforeSend: function () {
          btn.button('loading')
        },
        complete: function () {
          btn.button('reset')
        },
        success: function (json) {
          $('#modal-warn-on-downgrade').modal('hide')

          if (!json.error) {
            if (json.showPaymentModal) {
              accounting.mode = mode

              $('#modal-checkout .checkout-product').text(products.name)
              $('#modal-checkout .checkout-price').text(products.price)

              $('#modal-checkout').modal('show')
            } else {
              $('#modal-cash-register-products').modal('hide')
              products.modalSupportModell.modal('hide')
              toastr.success(json.msg)

              if ($('body[id="/intern/cash-register/signature/signature-de"]').length > 0) {
                signatureDe.reloadTseSteps()
              } else {
                products.loadProductCashRegister()
              }
            }
          } else {
            toastr.error(json.error)
          }

          products.setModeProcess = false
        },
      })
    }
  },

  handleSupportModell: function (mode, loadingBtn) {
    const prices = products.modalSupportModell.data('prices')
    const priceFormatted = helper.moneyFormat(prices[mode], '', 2, 2)
    products.modalSupportModell.find('.support-modell-price').text(priceFormatted)

    if (companyData.isDemo && companyData.country === 'at') {
      products.modalSupportModell.modal('show')
    } else {
      products.setCashRegisterMode(loadingBtn, mode)
    }
  },

  loadProductCashRegister: function () {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/products/load-product-cashregister',
      beforeSend: function () {
        $('.product-cashregister .product-box').addClass('load')
        $('.product-cashregister').next('.spinner').show()
      },
      complete: function () {
        $('.product-cashregister .product-box').removeClass('load')
        $('.product-cashregister').next('.spinner').hide()
      },
      success: function (json) {
        if (!json.error) {
          $('.product-cashregister .product-box').html(json.html)
        } else {
          toastr.error(json.error)
        }
      },
    })
  },

  setCashRegister: function (btn, enable) {
    $.request({
      method: 'POST',
      url: '/company/products/set-cash-register',
      json: {
        enable: enable,
      },
      loadingButton: btn,
    })
      .then(function (res) {
        if (enable) {
          $('#cashregister').show()
        } else {
          $('#cashregister').hide()
        }

        if (res.html) {
          $('.product-cashregister').html(res.html)
        }
      })
      .catch(function () {
        // handled by global toast
      })
  },

  setOnlineCalendar: function (btn, value) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/products/set-online-calendar',
      data: {
        enable: value,
      },
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (json) {
        if (!json.error) {
          if (value) {
            $('#bookings').show()
          } else {
            $('#bookings').hide()
          }

          $('.product-online-booking').html(json.html)
        }
      },
    })
  },

  //Enable/disable offer
  setOffer: function (btn, value) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/products/set-offer',
      data: {
        enable: value,
      },
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (json) {
        if (!json.error) {
          if (value) {
            $('#offer').show()
          } else {
            $('#offer').hide()
          }

          $('.product-offer').html(json.html)
        }
      },
    })
  },

  //Enable/disable delivery note
  setDeliveryNote: function (btn, value) {
    void $.ajax({
      type: 'POST',
      url: '/api/intern/company/products/set-delivery-note',
      data: {
        enable: value,
      },
      beforeSend: function () {
        btn.button('loading')
      },
      complete: function () {
        btn.button('reset')
      },
      success: function (json) {
        if (!json.error) {
          if (value) {
            $('#delivery-note').show()
          } else {
            $('#delivery-note').hide()
          }

          $('.product-delivery-note').html(json.html)
        }
      },
    })
  },
}
